<template>
    <a-layout>
        <a-layout-sider>
            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in parts" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">双变量分析结果</h1>
            <div class="result-area" id="varsProduce">
                <divider-title name="变量解释" />
                <div v-if="result&&result.bivariateBO&&result.bivariateBO.varsProduce">
                    <div v-for="(item,key) in result.bivariateBO.varsProduce" :key="key">{{item.key}}{{item.value}}</div>
                </div>
            </div>

            <div class="result-area" id="descStats">
                <divider-title name="描述性统计" />
                <a-table v-if="result&&result.bivariateBO&&result.bivariateBO.descStats" :dataSource="result.bivariateBO.descStats" :rowKey="(record,index)=>index" size="middle" :pagination="false">
                    <a-table-column key="varName" title="变量名" data-index="varName" :width="100" />
                    <a-table-column key="xmean" title="均值" data-index="xmean" align="right" :width="150">
                        <span slot-scope="text">{{toFixedNum(text)}}</span>
                    </a-table-column>
                    <a-table-column key="xstd" title="标准差" data-index="xstd" align="right" :width="150">
                        <span slot-scope="text">{{toFixedNum(text)}}</span>
                    </a-table-column>
                    <a-table-column key="xrealNum" title="样本个数" data-index="xrealNum" align="right" :width="150" />
                    <a-table-column key="blank" title="" data-index="blank">
                    </a-table-column>
                </a-table>
            </div>


            <div class="result-area" id="pearsonBO" v-if="this.result&&this.result.bivariateBO.pearsonBO">
                <divider-title name="相关性（Pearson系数）" />            
                <a-table 
                    :columns="pearsonData.columns" 
                    :data-source="pearsonData.list" 
                    :rowKey="(record,index)=>index" 
                    :pagination="false" 
                    size="middle"
                    :scroll="{x:pearsonData.columns.length>10?2500:true,y:pearsonData.list.length>10?500:true}" />
            </div>

            <div class="result-area" id="kendallBO" v-if="this.result&&this.result.bivariateBO.kendallBO">
                <divider-title name="相关性（Kendall系数）" />
                <a-table 
                    :columns="kendallData.columns" 
                    :data-source="kendallData.list" 
                    :rowKey="(record,index)=>index" 
                    :pagination="false" 
                    size="middle"
                    :scroll="{x:pearsonData.columns.length>10?2500:true,y:pearsonData.list.length>10?500:true}" />
            </div>

            <div class="result-area" id="spermanBO" v-if="this.result&&this.result.bivariateBO.spermanBO">
                <divider-title name="相关性（Sperman系数）" />
                <a-table 
                    :columns="spermanData.columns" 
                    :data-source="spermanData.list" 
                    :rowKey="(record,index)=>index" 
                    :pagination="false" 
                    size="middle"
                    :scroll="{x:pearsonData.columns.length>10?2500:true,y:pearsonData.list.length>10?500:true}" />
            </div>

            <div class="result-area">
                <a-button type="primary" @click="download(0)">下载WORD</a-button>
                <a-button type="primary" @click="download(1)">下载PDF</a-button>
            </div>
        </a-layout-content>
    </a-layout>
</template>

<script>
import config from '../../until/configs';
import { getSessionItem } from '../../until/tools'
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle},
    computed:{
        result() {
            let data = getSessionItem('analysis_bivar');
            return data.analysis_bivar;
        },
        parts() {
            let arr = [
                {name: '变量解释', keys: ['varsProduce']},
                {name: '描述性统计', keys: ['descStats']},
            ]
            if(this.result.bivariateBO.pearsonBO) {
                arr.push({name: '相关性（Pearson系数）', keys: ['pearsonBO']})
            }
            if(this.result.bivariateBO.kendallBO) {
                arr.push({name: '相关性（Kendall系数）', keys: ['kendallBO']})
            }
            if(this.result.bivariateBO.spermanBO) {
                arr.push({name: '相关性（Sperman系数）', keys: ['spermanBO']})
            }
            return arr;
        },
        pearsonData() {
            if(this.result&&this.result.bivariateBO.pearsonBO) {
                let data = this.getTableData(this.result.bivariateBO.pearsonBO);
                return data;
            } else {
                return {
                    columns:[],
                    list:[]
                }
            }
        },
        kendallData() {
            if(this.result&&this.result.bivariateBO.kendallBO) {
                let data = this.getTableData(this.result.bivariateBO.kendallBO);
                return data;
            } else {
                return {
                    columns:[],
                    list:[]
                }
            }
        },
        spermanData() {
            if(this.result&&this.result.bivariateBO.spermanBO) {
                let data = this.getTableData(this.result.bivariateBO.spermanBO);
                return data;
            } else {
                return {
                    columns:[],
                    list:[]
                }
            }
        }
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            if(num) {
                return num.toFixed(4);
            }else {
                return '-';
            }
        },
        getTableData(obj) {
            let result = {};
            let columns = [
                {
                    dataIndex: 'varname', 
                    key: 'varname',
                    title:'',
                    fixed:'left',
                    width:60,
                    customRender:(value,row,key)=>{
                        let rowSpan = 0;
                        if(key%4===0) {
                            rowSpan = 4;
                        }
                        return {attrs:{rowSpan:rowSpan},children:value}
                    }
                },
                {
                    dataIndex: 'name', 
                    key: 'name',
                    fixed:'left',
                    width:150
                },
            ];
            obj.varList.forEach(item=>{
                columns.push({dataIndex:item,key:item,title:item,align:'right',width:100});
            });
            columns.push({dataIndex:'blank',key:'blank',title:'',align:'right'});
            result.columns = columns;
            let arr = [];
            obj.items.forEach((item,key)=>{
                let ar1 = {name:'相关系数'};
                ar1.varname = 'S'+(key+1);
                let ar2 = {name:'Sig.('+this.result.signName+')'};
                ar2.varname = 'S'+(key+1);
                let ar3 = {name:'协方差'};
                ar3.varname = 'S'+(key+1);
                let ar4 = {name:'样本个数'};
                ar4.varname = 'S'+(key+1);
                item.forEach((i,k)=>{
                    ar1['S'+(k+1)] = i.correlations?i.correlations.toFixed(4):'-';
                    ar2['S'+(k+1)] = i.sigCorrelations?i.sigCorrelations.toFixed(4):'-';
                    ar3['S'+(k+1)] = i.covariance?i.covariance.toFixed(4):'-';
                    ar4['S'+(k+1)] = i.sampleNum?i.sampleNum:'-';
                });
                arr.push(ar1);
                arr.push(ar2);
                arr.push(ar3);
                arr.push(ar4);
            });
            result.list = arr;
            return result;
        },
        download(type) {
            let url = config.analysisResultDownload+'?action='+this.result.action+'&sheetId='+this.result.sheetId+'&type='+type+'&sid='+this.result.sid;
            window.open(url, '_blank');
        }
    }
}
</script>